import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { WebSocketObject } from '../../shared/model/WebSocketObject.model';
import { WebSocketObjectType } from '../../shared/enums/WebSocketObjectType.enum';
import { BehaviorSubject, Observable } from 'rxjs';
import { UserActivityModel } from '../../carte/pastille/user-activity.model';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class MessageService {
  private isConnectectionEstablished: boolean = false;

  private readonly backendUrl = environment.backendUrl.split('https://').pop();

  private messages$: BehaviorSubject<WebSocketObject[]> = new BehaviorSubject<
    WebSocketObject[]
  >([]);

  private userActivity$: BehaviorSubject<UserActivityModel> =
    new BehaviorSubject<UserActivityModel>(UserActivityModel.INACTIVE);

  private isTyping$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false,
  );

  private typingTimeout: any;

  private readonly TYPING_RESET_TIMER = 3000;

  private allMessages: WebSocketObject[] = [];

  private notifications$: BehaviorSubject<number> = new BehaviorSubject<number>(
    0,
  );
  private isNotificationPastilleDisplayed: boolean = true;

  private webSocket!: WebSocket;

  constructor(private userService: UserService) {}

  initWebSocketConnection() {
    if (this.isConnectectionEstablished) {
      return;
    }

    const token = this.userService.getUser().accessToken;

    this.webSocket = new WebSocket(
      `wss://${this.backendUrl}/map?token=${token}`,
    );

    this.webSocket.onmessage = (event) => {
      const message = JSON.parse(event.data) as WebSocketObject;

      if (message.webSocketObjectType === WebSocketObjectType.TYPING) {
        this.isTyping$.next(message.message === 'true');
        return;
      }

      if (message.webSocketObjectType === WebSocketObjectType.USER_STATE) {
        this.userActivity$.next(message.message as UserActivityModel);
        return;
      }

      const messages = [message, ...this.messages$.value];

      this.messages$.next(messages);
      this.allMessages.push(message);

      if (
        this.isNotificationPastilleDisplayed &&
        this.notifications$.value < 99
      ) {
        this.notifications$.next(this.notifications$.value + 1);
      }
    };

    this.isConnectectionEstablished = true;
  }

  closeWebSocketConnection() {
    if (this.webSocket) {
      this.webSocket.close();
    }
  }

  onUserTyping() {
    if (this.typingTimeout) {
      clearTimeout(this.typingTimeout);
    }

    if (!this.isTyping$.value) {
      this.sendTypingStatus();
    }

    this.typingTimeout = setTimeout(() => {
      this.sendTypingStatus(false);
    }, this.TYPING_RESET_TIMER);
  }

  private sendTypingStatus(isTyping: boolean = true) {
    const typingObject: WebSocketObject = {
      id: '', // Will be override in backend
      webSocketObjectType: WebSocketObjectType.TYPING,
      message: isTyping ? 'true' : 'false',
    };
    this.webSocket.send(JSON.stringify(typingObject));
  }

  getIsTyping(): Observable<boolean> {
    return this.isTyping$.asObservable();
  }

  getNotifications() {
    return this.notifications$.asObservable();
  }

  clearNotifications() {
    return this.notifications$.next(0);
  }

  setIsNotificationPastilleDisplayed(isDisplayed: boolean) {
    this.isNotificationPastilleDisplayed = isDisplayed;
  }

  getIsNotificationPastilleDisplayed() {
    return this.isNotificationPastilleDisplayed;
  }

  getMessages(): Observable<WebSocketObject[]> {
    this.setIsNotificationPastilleDisplayed(false);
    this.clearNotifications();

    return this.messages$.asObservable();
  }

  clearMessages() {
    this.messages$.next([]);
  }

  getAllPreviousMessagesAtNextRead() {
    this.messages$.next(this.allMessages);
  }

  sendMessage(message: string) {
    if (this.webSocket.readyState === WebSocket.OPEN) {
      this.sendTypingStatus(false);
      clearTimeout(this.typingTimeout);

      const messageObject: WebSocketObject = {
        id: '', // Will be override in backend
        webSocketObjectType: WebSocketObjectType.MESSAGE,
        message,
      };
      this.webSocket.send(JSON.stringify(messageObject));
    }
  }

  getUserActivity() {
    return this.userActivity$.asObservable();
  }

  sendUserState(state: UserActivityModel) {
    if (this.webSocket.readyState === WebSocket.OPEN) {
      const messageObject: WebSocketObject = {
        id: '', // Will be override in backend
        webSocketObjectType: WebSocketObjectType.USER_STATE,
        message: state,
      };
      this.webSocket.send(JSON.stringify(messageObject));
    }
  }
}
